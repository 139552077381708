import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Monitor your website for security threats"
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>If your website is attacked, orchestrating a fast response is key to minimizing the damage.
            But it can be hard to quickly fix that damage if you are unaware that an attack has occurred.
            Use Hexowatch to receive near-real-time alerts when a change has been made on your website that
            could indicate a security breach.
            </p>

            <h4>Monitor for Visual Changes</h4>

            <p>Very often, your users will be the first ones to encounter the damage caused by attacks and intrusions.
            Let’s look at the different types of security threats Hexowatch can help you identify
            before they affect your customers.
            </p>
            <h5>
              Website defacement attacks
            </h5>
            <p>
              Defacement is a type of attack where a hacker puts compromising material on your web page,
              which could be directed at you or someone else, or they might insert adverts that they can profit from.
              They can also put a call-to-action that takes your followers or clients away from your site.
            </p>
            <p>
              Our visual monitoring system can detect changes in the appearance of your website and provide proactive alerts
              when something changes.
            </p>

            <h5>Malicious web redirects</h5>
            <p>
              Attackers might take your highest-ranking pages and redirect visitors to a 3rd party page or a freshly
              created page on your website. The purpose of this is to use malicious adverts or code to trick visitors
              into downloading malware onto their personal computers.
            </p>
            <p>
              Hexowatch can detect hidden changes in the HTML code of your pages that can cause redirects and notify
              you if anything suspicious is found.
            </p>

            <h5>
              Spam changes
            </h5>
            <p>
              If your server is compromised, a hacker can use it to host thousands of spam pages.
              These pages are frequently used to advertise products that may not be legal.
              They can also be used to generate SEO links for another site, or they can be used to
              target visitors to your web page with spyware or malware.
            </p>
            <p>Hexowatch can be used to monitor and track changes to your published site map as well as changes in your technology stack.</p>



            <h4>Monitor for HTML Code Changes</h4>
            <p>Many of the issues described above can affect your site even if there are no visual indications of a hack.
            Hexowatch can also monitor changes to your source code and send alerts when necessary.</p>

            <h5>Malware & spyware</h5>

            <p>You have spent time building up a trustworthy profile and business.
            Attackers may seek to utilize that trust so that they can send malware and spyware to your users.
              Fortunately, these changes are detectable at an HTML level.</p>

            <p>With Hexowatch, you will be alerted when a change is made, and you’ll be able to see what part of
            the code was modified and compare the before and after of your HTML to fix the issue.
            </p>


            <h4>Monitor for Service & Disruption</h4>

            <p>If the attackers do not strike at content or code, then your server or database may be targeted directly.</p>

            <h5>Server connectivity</h5>

            <p>Use our uptime or keyword monitors to check if your web page is suffering from database or connectivity issues as a
              preemptive check to see if an important website or system feature becomes unavailable. </p>

            <p>In the case that Hexowatch cannot connect to your server or if it receives an error message, you will be notified. </p>

            <h4>Go beyond security monitoring</h4>

            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, keep an archive of every change and turn
              any website into your own private data source accessing changes as a downloadable CSV file, google sheets or via Zapier.</p>
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
